import React from "react";
import blogImg1 from "../assets/img/BBT23_Keyvisual_quadratisch_mit_gruenem_Hintergrund.webp";
import blogImg2 from "../assets/img/miwi.webp";
import blogImg4 from "../assets/img/MiwiFinsterwaldeAnsb45_1.webp";
import blogImg3 from "../assets/img/IMG_0054.webp";
import blogImg5 from "../assets/img/umfrage.webp";
import blogImg6 from "../assets/img/IMG_6130.webp";
import blogImg7 from "../assets/img/DrittesMiwiTreffen.webp";
import blogImg8 from "../assets/img/4_MiWI_Lausitz.webp";
import blogImg9 from "../assets/img/IMG_9000.webp";

function News() {
  const blogList = [
    {
      header:
        "Bald geht es los: Die 4. MiWi Lausitz Dialogreihe startet am 19. März mit dem Thema „Arbeitswelten im Dialog“!",
      date: "13.03.2025",
      info: [
        <div className="blog-card-info">
          <p>
            Die Vorfreude steigt! Am{" "}
            <span className="fw-bold">19. März 2025</span> öffnen sich die Türen
            des wunderschönen{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.elsterpark-herzberg.de/gastronomie/events/"
              className="link">
              TagungsHauses im Elsterpark Herzberg (Elster)
            </a>{" "}
            für die 4. Dialogreihe MiWi Lausitz. Unter dem Motto „Arbeitswelten
            im Dialog: Gemeinsam verstehen & voneinander lernen“ freuen wir uns
            auf spannende Diskussionen, inspirierende Vorträge und einen offenen
            Austausch mit Expert:innen und Teilnehmenden.
          </p>
          <br />
          <p>
            Besonders freuen wir uns, dass die{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.herzberg-elster.de/verwaltung/mitarbeiter/id/20277/frau-stephanie-kuntze.html"
              className="link">
              stellvertretende Bürgermeisterin der Stadt Herzberg (Elster), Frau
              Stephanie Kuntze,
            </a>{" "}
            mit einem Grußwort die Veranstaltung eröffnen wird! Mit ihrer
            Erfahrung und Perspektive bereichert sie unsere Runde und setzt
            wertvolle Impulse für den Dialog rund um die Zukunft der
            Arbeitswelt.
          </p>
          <br />
          <p>
            <span className="fw-bold">
              Jetzt anmelden – Noch wenige Plätze frei!
            </span>
          </p>
          <br />
          <p>
            Es sind nur noch wenige Plätze verfügbar – sichern Sie sich jetzt
            Ihre Teilnahme! Die Anmeldung ist ganz einfach über das
            untenstehende Anmeldeformular auf der Webseite{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.miwi-lausitz.de/"
              className="link">
              www.miwi-lausitz.de
            </a>{" "}
            möglich.
          </p>
          <br />
          <p>
            Wann? 19. März 2025 | Einlass ab 14:30 Uhr, Beginn 15:00 Uhr,
            Abschluss gegen 18:30 Uhr
          </p>
          <p>
            Wo? TagungsHaus im Elsterpark Herzberg, Badstr. 29-30, 04916
            Herzberg (Elster)
          </p>
          <br />
          <p>
            Wir freuen uns auf einen inspirierenden Nachmittag voller neuer
            Impulse, wertvoller Begegnungen und spannender Dialoge.
          </p>
          <br />
          <p>Ihr Team von MiWi Lausitz</p>
        </div>,
      ],
      img: blogImg9,
      index: 1,
    },
    {
      header: "4. MiWi Lausitz Dialogreihe",
      date: "29.01.2025",
      info: [
        <div className="blog-card-info">
          <p>
            Mit der <span className="fw-bold">4. MiWi Lausitz Dialogreihe</span>{" "}
            setzen wir unseren interdisziplinären Austausch fort. Unter dem
            Motto ‚Arbeitswelten im Dialog: Gemeinsam verstehen & voneinander
            lernen‘ bringen wir Fachkräfte aus{" "}
            <span className="fw-bold">
              ambulanten Praxen, der Therapie, der Sozialarbeit, dem
              Pflegedienst und weiteren Gesundheitsberufen
            </span>{" "}
            zusammen. Die Veranstaltung beleuchtet die Zusammenarbeit in der
            Gesundheitsversorgung und bietet wertvolle Einblicke in berufliche
            Perspektiven, Digitalisierung und interdisziplinäre Zusammenarbeit.
          </p>
          <br />
          <p>
            Es erwarten Sie spannende Vorträge von{" "}
            <span className="fw-bold">Vivienne Schlurmann (M.Sc.)</span> zu
            Glück am Arbeitsplatz und{" "}
            <span className="fw-bold">Editha Kunzke-Mayer (B.A.)</span>, die
            über ihr Tauschexperiment{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.zdf.de/kultur/takeover-drei-tage-raus"
              className="link">
              „TakeOver – Drei Tage raus“
            </a>{" "}
            berichtet.
          </p>
          <br />
          <p>
            <span className="fw-bold">Datum:</span> 19. März 2025, 14:30 Uhr
          </p>
          <p>
            <span className="fw-bold">Ort: </span> ElsterPark, Badstr. 29-30,
            04916 Herzberg (Elster)
          </p>
          <br />
          <p>
            <span className="fw-bold">Weitere Informationen & Anmeldung:</span>{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.miwi-lausitz.de"
              className="link">
              www.miwi-lausitz.de
            </a>{" "}
          </p>
          <br />
          <p>
            Wir freuen uns darauf, Sie persönlich bei der 4. MiWi Lausitz
            Dialogreihe zu begrüßen!
          </p>
          <br />
          <p>Mit herzlichen Grüßen, Ihr MiWi Lausitz-Team</p>
        </div>,
      ],
      img: blogImg8,
      index: 2,
    },
    {
      header:
        "Rückblick auf die 3. MiWi Lausitz: Kommunikation und Digitalisierung im Gesundheitswesen",
      date: "24.09.2024",
      info: [
        <div className="blog-card-info">
          <p>
            Am 18.09.2024 fand in Bad Liebenwerda eine inspirierende
            Veranstaltung zum Thema Digitalisierung im Gesundheitswesen statt.
            Vielen Dank an Frau Schrader für die Bereitstellung der
            Räumlichkeiten des{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.b-tu.de/umfragen/index.php/828153?newtest=Y"
              className="link">
              HausLeben
            </a>
            . Zu Beginn sorgte eine interaktive Abfrage mittels Mentimeter für
            ein lockeres Kennenlernen und regte den Austausch über die
            Wahrnehmung der Digitalisierung im eigenen Arbeitsfeld an.{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link"
              href="https://www.lkee.de/Service-Verwaltung/Kreisverwaltung/B%C3%BCro-des-Landrates/Beauftragte-des-Landkreises/Integrationsbeauftragter-Stabsstelle-/">
              Frau Hädicke, Integrationsbeauftragte des Landkreises Elbe-Elster
            </a>{" "}
            , eröffnete die Veranstaltung mit einem Grußwort und einem Vortrag
            über die Chancen der Digitalisierung für Menschen mit Behinderung
            und Nichtmuttersprachler*innen.
          </p>
          <br />
          <p>
            <span className="fw-bold">
              Digitalisierung im Gesundheitswesen – Chancen und
              Herausforderungen
            </span>
            <br />
            Im Fokus stand der Vortrag von{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://mul-ct.de/index/kontakte.php?id_object=843&&object=contact&&tab=ueber-uns"
              className="link">
              Frau Kruhøffer vom Kompetenzzentrum Pflege der Medizinischen
              Universität Lausitz – Carl Thiem
            </a>{" "}
            (MUL-CT). Sie betonte die Bedeutung digitaler Kompetenzen und
            beleuchtete die erfolgreiche Implementierung digitaler Prozesse in
            Dänemark. Besonders beeindruckend war, wie die Digitalisierung die
            Kommunikation und Dokumentation in der Pflege und im
            Gesundheitswesen erleichtern kann. Im anschließenden World Café
            diskutierten die Teilnehmer*innen Themen wie digitale Patienten- und
            Teamkommunikation sowie digitale Kompetenzen. Die intensiven
            Gespräche führten zu wertvollen Erkenntnissen und praxisnahen Ideen.
          </p>
          <br />
          <p>
            <span className="fw-bold">
              Schulung und Weiterbildung – Ein dringendes Bedürfnis
            </span>
            <br />
            Die Teilnehmer*innen betonten die Notwendigkeit gezielter
            Schulungen, um die digitale Kompetenz weiter auszubauen und Hürden
            in der Anwendung zu überwinden.
          </p>
          <br />
          <p>
            <span className="fw-bold"> Gemeinsam in die digitale Zukunft </span>
            <br />
            Ein großes Dankeschön an alle Teilnehmer*innen für ihre aktive
            Beteiligung und an den WochenKurier für die mediale Begleitung.
          </p>
          <br />
          <p>
            <span className="fw-bold">Save the Date: </span> Die nächste 4. MiWi
            Lausitz findet am 19.03.2025 in Herzberg statt – weitere Infos und
            die Anmeldung folgen bald auf unserer Website.
          </p>
          <br />
          <p>
            Wir freuen uns auf die nächste Veranstaltung und darauf, gemeinsam
            mit Ihnen zukunftsweisende Ideen zu entwickeln!
          </p>
          <br />
          <p>Euer MiWi-Team</p>
        </div>,
      ],
      img: blogImg7,
      index: 3,
    },
    {
      header: "Ein voller Erfolg: 2. Dialogreihe MiWi Lausitz gestartet",
      date: "20.03.2024",
      info: [
        <div className="blog-card-info">
          <p>
            Voller Euphorie blicken wir auf die 2. Veranstaltung MiWi Lausitz
            vom 20.03.2024 im „Haus des Gastes“ in Falkenberg/Elster zurück. Es
            war mal wieder ein großartiges Zusammenkommen, mit tollen Menschen
            und bereichernden Gesprächen.
          </p>
          <br />
          <p>
            Die Veranstaltung war interaktiv und abwechslungsreich gestaltet. So
            sind unserer Einladung Mitarbeiter*innen der Caritas,
            Pflegeeinrichtungen und Arztpraxen gefolgt. Nach einem Impulsvortrag
            von Herrn Roick (Kommunikationscoach) zum Thema: Interprofessionelle
            Kommunikation leben & gestalten“, gab es viel Raum für die
            Teilnehmer*innen - mittels der Methode „World Café“ - wertvolle
            Erfahrungen und Meinungen untereinander auszutauschen.
          </p>
          <br />
          <p>
            Das Fazit: Gegenseitige Wertschätzung und gute Arbeitsabläufe sind
            wichtige Faktoren im Arbeitsalltag. Wir danken allen
            Teilnehmer*innen für die aktive Mitgestaltung unserer Dialogreihe
            „Miteinander Wissen“. Ein herzliches Danke gilt auch Frau
            Gundermann, Leiterin der Stabsstelle Sozialplanung und
            Daseinsvorsorge im LK Elbe-Elster, für die einleitenden Grußworte.
            Wir freuen uns schon jetzt auf die Rückkehr unserer Feedback-Karten
            – und auf die nächste Veranstaltung am 18.09.2024 im „Haus Leben“ in
            Bad Liebenwerda.
          </p>
          <br />
          <p>Euer MiWi-Team</p>
        </div>,
      ],
      img: blogImg6,
      index: 4,
    },
    {
      header: "Umfragestart zur digitalen Kompetenz in der Lausitz",
      date: "16.01.2024",
      info: [
        <div className="blog-card-info">
          <p>
            Wir sind ganz Ohr – Umfrage zur digitalen Kompetenz in der Lausitz
            ist gestartet!
          </p>
          <br />
          <p>
            Wir – der Ärztenetz Südbrandenburg e.V. – sind Teil des WIR! -
            Bündnisses com(m) 2020 für kommunale Innovationen. Ziel ist es, die
            Gesundheitsversorgung der Lausitz aktiv zu gestalten und im
            Strukturwandel zu stärken. Mit unserem Verbundprojekt „digitaler
            Praxisfußabdruck in der Lausitz“ (digiPLZ) führen wir eine Analyse
            zu Arztpraxen, Zahnarztpraxen und Apotheken durch.
          </p>
          <br />
          <p>
            Seit Jahren treiben digitale Technologien den Wandel der Arbeitswelt
            voran und es gibt kaum einen Beruf, der nicht von der digitalen
            Transformation erfasst wurde. Mit dem Fragebogen erfassen wir unter
            anderem die Einschätzung zu digitalen Kompetenzen.
          </p>
          <br />
          <p>Machen Sie mit!</p>
          <a
            className="btn"
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.b-tu.de/umfragen/index.php/828153?newtest=Y">
            Zur Umfrage
          </a>
        </div>,
      ],
      img: blogImg5,
      index: 5,
    },
    {
      header: "1. Auftaktveranstaltung MiWi Lausitz",
      date: "06.09.2023",
      info: [
        <div className="blog-card-info">
          <p>
            Die 1. Auftaktveranstaltung MiWi Lausitz am 06.09.2023 in der&nbsp;
            <a
              href="https://www.kulturweberei-finsterwalde.de/"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              Kulturweberei
            </a>
            &nbsp; Finsterwalde war ein voller Erfolg. Wir begrüßten 50
            interessierte nichtärztliche Expert*innen ambulanter Praxen aus den
            Landkreisen Elbe-Elster und Oberspreewald Lausitz. Unser besonderer
            Dank geht an die stellvertretende Bürgermeisterin von Finsterwalde
            Frau Anja Zajic für das wertschätzende Grußwort, sowie an den
            Referent Henry Roick für den inspirierenden Impulsvortrag. Ebenso
            ein großes Dankschön an Herrn Gallin für die persönliche Führung
            durch die Kulturweberei Finsterwalde &nbsp;
            <a
              href="https://www.kulturweberei-finsterwalde.de/"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              Kulturweberei
            </a>
            &nbsp;. Weitere Eindrücke und Bilder der 1. Auftaktveranstaltung
            MiWi Lausitz finden sie &nbsp;
            <a
              href="https://www.instagram.com/aerzteundgesundheitsnetzsuedbb/"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              hier
            </a>
            .
          </p>
          <br />
          <p>
            Wir bedanken uns bei allen, die diese 1. Auftaktveranstaltung
            möglich gemacht haben und freuen uns schon auf das nächste&nbsp;
            <a
              href="https://www.miwi-lausitz.de/"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              2. MiWi Lausitz Treffen
            </a>
            &nbsp;am 20.03.2024 in Falkenberg Haus des Gastes.
          </p>
        </div>,
      ],
      img: blogImg4,
      index: 6,
    },
    {
      header: "Brandenburg-Tag Finsterwalde",
      date: "02.09 - 03.09.2023",
      info: [
        <div className="blog-card-info">
          <p>
            Wir blicken auf ein großartiges Wochenende dem&nbsp;
            <a
              href="https://www.brandenburgtag-finsterwalde.de/"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              17. Brandenburg-Tag 2023
            </a>
            &nbsp;in Finsterwalde zurück. Wir bedanken uns herzlich bei allen
            Besuchern und Besucherinnen für den interessanten Austausch und das
            rege Interesse an unserer Arbeit. Vielen Dank an alle Organisatoren,
            die dieses beeindruckende Event auf die Beine gestellt haben.
          </p>
          <br />
          <p>
            Weitere Bilder und Impressionen vom Festwochenende finden Sie&nbsp;
            <a
              href="https://www.instagram.com/p/CwxqK0wttlS/"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              hier
            </a>
          </p>
        </div>,
      ],
      img: blogImg1,
      index: 7,
    },
    {
      header: "MiWi Lausitz",
      date: "07.08.2023",
      info: [
        <div className="blog-card-info">
          <p>
            Die neue Reihe „MiWi Lausitz“ bietet MFA’s und Auszubildenden
            ambulanter Praxen eine neue Plattform zur Vernetzung und Austausch.
            Zur Auftaktveranstaltung am 06.09.2023 in der&nbsp;
            <a
              href="https://www.kulturweberei-finsterwalde.de/index.php?object=tx,3856.4.1&ModID=11&FID=3856.41.1"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              Kulturweberei
            </a>
            &nbsp;in Finsterwalde erwartet Sie in lockerer Atmosphäre u.a. ein
            Impulsreferat von Herrn&nbsp;
            <a
              href="https://www.henryroick-consulting.de/home.html"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              Henry Roick
            </a>
            &nbsp;zum Thema Kommunikation. Weitere Informationen finden
            Sie&nbsp;
            <a
              href="https://www.miwi-lausitz.de/"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              hier
            </a>
          </p>
        </div>,
      ],
      img: blogImg2,
      index: 8,
    },
    {
      header:
        "Projektstart digitaler Praxisfußabdruck in der Lausitz (DigiPLZ)",
      date: "01.07.2023",
      info: [
        <div className="blog-card-info">
          <p>
            Mit dem vom BMBF geförderten Projekt führen wir eine
            Grundlagenanalyse zu Standortstrukturen in der Lausitz durch und
            erarbeiten neue methodische Konzepte zur erfolgreichen Umsetzung von
            digitalen Transformationsprozessen und Maßnahmen zur
            Fachkräftegewinnung und -sicherung. Weitere Informationen finden sie
            &nbsp;
            <a
              href="https://www.gesunde-lausitz.de/comm2020/project/124"
              target="_blank"
              rel="noreferrer noopener"
              className="link">
              hier
            </a>
          </p>
        </div>,
      ],
      img: blogImg3,
      index: 9,
    },
  ];

  const Card = (item) => {
    return (
      <div className="blog-card">
        <div className="blog-card-content-wrapper">
          <img className="blog-card-img" src={item.img} alt="blog-img" />
          <div className="blog-card-content">
            <div className="blog-card-header">{item.header}</div>
            <div className="blog-card-date">{item.date}</div>
          </div>
        </div>
        {item.info}
      </div>
    );
  };

  const CardList = () => {
    const cardListComponent = [];

    blogList.forEach((item) => {
      cardListComponent.push(Card(item));
      if (blogList.length !== item.index) {
        cardListComponent.push(<div className="horizontal-line"></div>);
      }
    });

    return <div className="blog-card-list">{cardListComponent}</div>;
  };

  return (
    <section id="news">
      <div className="container">
        <div className="padding-block-700">
          <div className="primary-heading">Aktuelles</div>
          {CardList()}
        </div>
      </div>
    </section>
  );
}

export default News;
